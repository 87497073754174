import { mapState } from 'vuex'

export default {
    ...mapState(['batch', 'batches']),
    isCurrentBatch() {
        let $this = this;
        let output = false;
        this.batches.map(function (batch) {
            if ($this.batch.id === batch.id && batch.is_current_batch === true) {
                output = true;
            }
        });
        return output;
    },
    guardianItems() {
        if (this.guardianList && this.guardianList.length > 0)
            return this.guardianList.map(entry => {
                let name = entry.name;
                if (entry.primary_mobile) {
                    name += ` (${entry.primary_mobile})`
                }
                return Object.assign({}, entry, { Name: name, address: entry.address })
            })
    },
    guardianSelected() {
        let guardian = {};

        if (this.guardianItems && this.guardianItems.length) {
            let $this = this;
            this.guardianItems.map(function (data) {
                if ($this.form.familyInfo.data.guardian_id === data.id) {
                    guardian = data;
                }
            });
        }
        return guardian;
    },
    isStep1Complete() {
        // if (Object.keys(this.form.studentInfo.data).length < 5) return false;

        return (this.form.studentInfo.data.fname && this.form.studentInfo.data.lname && this.form.studentInfo.data.gender && this.form.studentInfo.data.dob && this.form.studentInfo.data.religion && this.form.studentInfo.data.ethnicity);
    },


    isStep3Complete() {
        if (Object.keys(this.form.addressInfo.data).length < 10) return false;

        return (this.form.addressInfo.data.permanent_state_id !== '' && this.form.addressInfo.data.permanent_district_id !== '' && this.form.addressInfo.data.permanent_city_id !== '' && this.form.addressInfo.data.permanent_ward_no !== '' && this.form.addressInfo.data.permanent_address_line !== '' && this.form.addressInfo.data.temporary_state_id !== '' && this.form.addressInfo.data.temporary_district_id !== '' && this.form.addressInfo.data.temporary_city_id !== '' && this.form.addressInfo.data.temporary_ward_no !== '' && this.form.addressInfo.data.temporary_address_line !== '');
    },


    isStep2Complete() {
        if (!this.guardianSelected.name || !this.form.familyInfo.data.relation) return false;
        return true;
    },


    isStep4Complete() {
        return (this.grade && this.section);
    },


    optionalSubName() {
        let $this = this;
        let output = [];
        this.optional_groups.map(function (item) {
            if ($this.optional_sub.includes(item.value)) {
                output.push(item.text);
            }
        });
        return output;
    }

};