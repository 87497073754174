export default {
    'table.pagination': {
        handler() {
            this.all()
        },
        deep: true
    },
    'form.studentInfo.data.fname': {
        handler(name) {
            this.form.studentInfo.data.fname = this.capitalizeString(name)
        }
    },
    'form.studentInfo.data.mname': {
        handler(name) {
            this.form.studentInfo.data.mname = this.capitalizeString(name)
        }
    },
    'form.studentInfo.data.lname': {
        handler(name) {
            this.form.studentInfo.data.lname = this.capitalizeString(name)
        }
    },
    'form.addressInfo.data.permanent_state_id': {
        handler(v) {
            this.getDistricts('permanent', v)
        }
    },
    'form.addressInfo.data.permanent_district_id': {
        handler(v) {
            this.getCities('permanent', v)
        }
    },

    'form.addressInfo.data.permanent_city_id': {
        handler(v) {
            this.getWardCount('permament');
        }
    },
    'form.addressInfo.data.permanent_address_line': {
        handler(name) {
            this.form.addressInfo.data.permanent_address_line = this.capitalizeString(name)
        }
    },
    'form.addressInfo.data.temporary_address_line': {
        handler(name) {
            this.form.addressInfo.data.temporary_address_line = this.capitalizeString(name)
        }
    },
    'form.addressInfo.data.temporary_state_id': {
        handler(v) {
            this.getTemporaryDistricts('temporary', v)
        }
    },
    'form.addressInfo.data.temporary_district_id': {
        handler(v) {
            this.getTemporaryCities('temporary', v)
        }
    },
    'form.addressInfo.data.temporary_city_id': {
        handler() {
            this.getWardCount('temporary');
        }
    },
    'same_address': {
        handler(v) {
            this.copyAddress(v);
        }
    },
    search(val) {
        val && this.searchGuardians(val)
    },
    'base': function () {
        this.getGrades();
    },
    'section': function () {
        this.getOptionalGroups();
    },
    'form.familyInfo.data.guardian_id': {
        handler(v) {
            if (!this.guardianItems) return;
            const guardian = this.guardianItems.find(guardian => guardian.id === v);
            if (guardian && guardian.address && guardian.address.permanent) {
                this.form.addressInfo.data.permanent_state_id = parseInt(guardian.address.permanent.state_id);
                this.form.addressInfo.data.permanent_district_id = parseInt(guardian.address.permanent.district_id);
                this.form.addressInfo.data.permanent_city_id = parseInt(guardian.address.permanent.city_id);
                this.form.addressInfo.data.permanent_ward_no = parseInt(guardian.address.permanent.ward_no);
                this.form.addressInfo.data.permanent_address_line = (guardian.address.permanent.street);
            }
        }
    },
    'grade': function (value) {
        this.optional_groups = [];
        this.getSections();
    },
    'searchGuardian': function (val) {
        if (!val) return;
        if (this.isLoading) return;
        this.isLoading = true;

        if (val.match(/\d+/g)) {
            val = val.replace(/\D/g, '');
        }

        this.$rest.get('api/user-search/slim?type=guardian&search=' + val)
            .then(({ data }) => {
                this.guardianList = data.data
            }).catch(err => {
            }).finally(() => (this.isLoading = false))


    }
};