import statesData from '@/assets/json/states.json'
import districtDataData from '@/assets/json/districts.json'
import cityData from '@/assets/json/cities.json'
import Form from '@/library/Form';
import { validateDate } from "../../../../../library/helpers";

const NepaliDate = require('nepali-date');

export default {
    sideBarClosed: false,
    header: {
        title: 'Admit Student',
        description: '',
        keywords: '',
        image: '',
        lang: 'en',
        ogLocale: 'en',
        url: ''
    },
    isLoading: false,
    cropperImg: {
        cropDialog: false,
        imgSrc: '',
        cropImg: '',
        uploadCropped: {},
        name: null
    },
    guardianList: [],
    guardianForm: new Form({
        password: '12345678',
        email: '',
        lname: '',
        fname: '',
        mname: '',
        primary_mobile: ''
    }, '/api/guardian'),
    p_show: false,
    searchGuardian: '',
    route: 'api/student',
    admission_date: new NepaliDate(new Date()).format('YYYY-MM-DD'),
    step: 1,
    search: null,
    ward_count_permanent: [],
    ward_count_temporary: [],

    //date validation
    is_custom_date_valid: true,
    dateValidation: [
        (v) => validateDate(v) || 'Invalid Date Format',
    ],
    form: {
        studentInfo: {
            data: {
                admission_date: ''
            },
            validation: {
                valid: false,
                fnameRule: [
                    (v) => !!v || 'First name is required!'
                ],
                admissionDateRule: [
                    (v) => !!v || 'First name is required!'
                ],
                lnameRule: [
                    (v) => !!v || 'Last name is required!'
                ],
                usernameRule: [
                    (v) => !!v || 'Username is required!'
                ],
                dobRule: [
                    (v) => !!v || 'Birth Date is required!'
                ],
                photoRule: [
                    (v) => !!v || 'Photo is required!'
                ],
                genderRule: [
                    (v) => !!v || 'Gender is required!'
                ],
                ethnicityRule: [
                    (v) => !!v || 'Ethnicity is required!'
                ],
                religionRule: [
                    (v) => !!v || 'Religion is required!'
                ]
            }
        },
        addressInfo: {
            data: {
                permanent_state_id: '',
                permanent_district_id: '',
                permanent_city_id: '',
                permanent_address_line: ''
            },
            validation: {
                valid: false,
                permanent_state_id_rule: [
                    (v) => !!v || 'Please select state!'
                ],
                temporary_state_id_rule: [
                    (v) => !!v || 'Please select state!'
                ],
                permanent_address_line_rule: [
                    (v) => !!v || 'Address is required!'
                ],
                temporary_address_line_rule: [
                    (v) => !!v || 'Address is required!'
                ],
                permanent_district_id_rule: [
                    (v) => !!v || 'Please select district!'
                ],
                temporary_district_id_rule: [
                    (v) => !!v || 'Please select district!'
                ],
                permanent_city_id_rule: [
                    (v) => !!v || 'Please select municipality/city!'
                ],
                temporary_city_id_rule: [
                    (v) => !!v || 'Please select municipality/city!'
                ],
                permanent_ward_no: [
                    (v) => !!v || 'Please select Ward No!'
                ],
                temporary_ward_no: [
                    (v) => !!v || 'Please select Ward No!'
                ],
            }
        },
        familyInfo: {
            data: {},
            validation: {
                valid: true,
                relation: [
                    (v) => !!v || 'Please select Relation!'
                ],
                guardian_id: [
                    (v) => !!v || 'Please select Guardian!'
                ],
            }
        },
        enrollInfo: {
            data: {},
            validation: {
                valid: false,
                base_idRule: [
                    (v) => !!v || 'Please select level!'
                ],
                grade_idRule: [
                    (v) => !!v || 'Please select class!'
                ],
                section_idRule: [
                    (v) => !!v || 'Please select section!'
                ]
            }
        },
        error: null
    },
    gender: [
        {
            value: 'male',
            text: 'Male'
        }, {
            value: 'female',
            text: 'Female'
        }, {
            value: 'other',
            text: 'Other'
        }
    ],
    blood: [
        {
            value: 'AB+',
            text: 'AB+'
        }, {
            value: 'AB-',
            text: 'AB-'
        }, {
            value: 'A+',
            text: 'A+'
        }, {
            value: 'A-',
            text: 'A-'
        }, {
            value: 'B+',
            text: 'B+'
        }, {
            value: 'B-',
            text: 'B-'
        }, {
            value: 'O+',
            text: 'O+'
        }, {
            value: 'O-',
            text: 'O-'
        }
    ],
    levels: [
        {
            value: 'basic',
            text: 'Basic'
        }, {
            value: 'pre-Basic',
            text: 'Pre-basic'
        }
    ],
    relations: ['Grandfather', 'Grandmother', 'Father', 'Mother', 'Brother', 'Sister', 'Uncle', 'Aunt', 'Other'],
    religions: ['Hindu', 'Muslim', 'Buddhist', 'Christian', 'Other'],
    ethnicities: ['Brahmin', 'Chhetri', 'Janajati', 'Dalit', 'Muslim', 'Madhesi', 'Other'],
    imagePicker: {
        name: null,
        image: null,
        url: null
    },
    same_address: false,
    states: [],
    progressDialog: false,
    errorDialog: false,
    permanent_districts: [],
    temporary_districts: [],
    permanent_cities: [],
    temporary_cities: [],
    guardians: [],
    guardian: {},
    guardianLoading: false,
    bases: [],
    base: null,
    grades: [],
    grade: null,
    sections: [],
    section: null,
    optional_groups: [],
    optional_sub: [],
    duplicate: null,
    statesData,
    districtDataData,
    cityData,
};